import React from 'react';
import {IconType} from '../../icons';

export const Labels: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M98.854 43.8C100.043 43.8 101.176 44.304 101.973 45.187L113.492 57.9587C114.934 59.557 114.934 61.9864 113.492 63.5847L101.973 76.3564C101.176 77.2394 100.043 77.7434 98.854 77.7434H49.6263C47.3067 77.7434 45.4263 75.863 45.4263 73.5434L45.4263 48C45.4263 45.6804 47.3067 43.8 49.6263 43.8L98.854 43.8ZM102.471 65.405C104.838 65.405 106.756 63.4867 106.756 61.1204C106.756 58.754 104.838 56.8357 102.471 56.8357C100.105 56.8357 98.1866 58.754 98.1866 61.1204C98.1866 63.4867 100.105 65.405 102.471 65.405Z"
        stroke="#001F5C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.567 83.1077C101.619 82.0566 100.27 81.4565 98.854 81.4565L49.6263 81.4565C46.8649 81.4565 44.6263 83.6951 44.6263 86.4565V112C44.6263 114.761 46.8649 117 49.6263 117L98.854 117C100.27 117 101.619 116.4 102.567 115.349L114.086 102.577C115.803 100.674 115.803 97.7821 114.086 95.8794L102.567 83.1077ZM102.471 103.062C104.396 103.062 105.956 101.501 105.956 99.5769C105.956 97.6524 104.396 96.0923 102.471 96.0923C100.547 96.0923 98.9866 97.6524 98.9866 99.5769C98.9866 101.501 100.547 103.062 102.471 103.062ZM51.5957 95.978C51.5957 94.8233 52.5318 93.8872 53.6865 93.8872H89.2299C90.3846 93.8872 91.3206 94.8233 91.3206 95.978C91.3206 97.1327 90.3846 98.0688 89.2299 98.0688H53.6865C52.5318 98.0688 51.5957 97.1327 51.5957 95.978ZM53.6865 101.553C52.5318 101.553 51.5957 102.489 51.5957 103.644C51.5957 104.799 52.5318 105.735 53.6865 105.735H74.5944C75.7491 105.735 76.6851 104.799 76.6851 103.644C76.6851 102.489 75.7491 101.553 74.5944 101.553H53.6865Z"
        fill="#3164FA"
      />
    </svg>
  );
};
