import React from 'react';
import {IconType} from '../../icons';

export const Chain: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6545 61.9466C57.7262 62.5452 57.7262 63.5157 58.6545 64.1143L78.3191 76.795C79.2474 77.3936 80.7525 77.3936 81.6808 76.795L101.345 64.1143C102.274 63.5157 102.274 62.5452 101.345 61.9466L81.6808 49.2659C80.7525 48.6672 79.2474 48.6672 78.3191 49.2659L58.6545 61.9466ZM79.6638 50.133L59.9991 62.8137C59.8135 62.9334 59.8135 63.1275 59.9991 63.2472L79.6638 75.9279C79.8495 76.0476 80.1505 76.0476 80.3361 75.9279L100.001 63.2472C100.186 63.1275 100.186 62.9334 100.001 62.8137L80.3361 50.133C80.1505 50.0132 79.8495 50.0132 79.6638 50.133Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6545 95.886C57.7262 96.4846 57.7262 97.4552 58.6545 98.0538L78.3191 110.734C79.2474 111.333 80.7525 111.333 81.6808 110.734L101.345 98.0538C102.274 97.4552 102.274 96.4846 101.345 95.886L81.6808 83.2053C80.7525 82.6067 79.2474 82.6067 78.3191 83.2053L58.6545 95.886ZM79.6638 84.0724L59.9991 96.7531C59.8135 96.8728 59.8135 97.0669 59.9991 97.1867L79.6638 109.867C79.8495 109.987 80.1505 109.987 80.3361 109.867L100.001 97.1867C100.186 97.0669 100.186 96.8728 100.001 96.7531L80.3361 84.0724C80.1505 83.9527 79.8495 83.9527 79.6638 84.0724Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6808 81.084C30.7525 80.4854 30.7525 79.5149 31.6808 78.9163L51.3455 66.2356C52.2738 65.637 53.7789 65.637 54.7072 66.2356L74.3718 78.9163C75.3001 79.5149 75.3001 80.4854 74.3718 81.084L54.7072 93.7648C53.7789 94.3634 52.2738 94.3634 51.3455 93.7648L31.6808 81.084ZM33.0255 79.7834L52.6902 67.1027C52.8758 66.983 53.1768 66.983 53.3625 67.1027L73.0272 79.7834C73.2128 79.9031 73.2128 80.0972 73.0272 80.2169L53.3625 92.8976C53.1768 93.0174 52.8758 93.0174 52.6902 92.8976L33.0255 80.2169C32.8398 80.0972 32.8399 79.9031 33.0255 79.7834Z"
        fill="#001F5C"
      />
      <rect
        width="27.3987"
        height="27.3987"
        rx="2"
        transform="matrix(0.840417 -0.541941 0.840417 0.541941 83.9473 80)"
        fill="#3164FA"
      />
    </svg>
  );
};
