import React from 'react';
import {IconType} from '../../icons';

export const Action: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4508 79.8342C40.4508 71.8362 46.9345 65.3525 54.9325 65.3525H105.015C113.013 65.3525 119.497 71.8362 119.497 79.8342C119.497 83.6036 118.057 87.0366 115.697 89.6131L99.7452 82.4434L103.549 94.3159H54.9325C46.9345 94.3159 40.4508 87.8323 40.4508 79.8342ZM92.6851 75.6567L82.6589 80.0686V76.6657C82.6592 75.2773 81.5436 74.1517 80.1674 74.1514C79.8243 74.1514 79.4848 74.2227 79.1702 74.3612L65.6561 80.3083C64.3958 80.8662 63.8225 82.3492 64.3755 83.6204C64.9268 84.8879 66.3898 85.4674 67.6486 84.9168L77.6751 80.5046V83.9075C77.6749 85.2959 78.7905 86.4215 80.1667 86.4217C80.5098 86.4217 80.8493 86.3503 81.1638 86.2119L94.678 80.2647C95.9396 79.7097 96.5161 78.228 95.966 76.9555C95.4158 75.6828 93.9466 75.1017 92.6851 75.6567Z"
        fill="#3164FA"
      />
      <path
        d="M121.607 64.2583C121.314 63.9277 121.344 63.4221 121.675 63.129L131.189 54.6985C131.52 54.4055 132.025 54.436 132.318 54.7667C132.611 55.0974 132.581 55.603 132.25 55.896L122.736 64.3265C122.405 64.6196 121.9 64.589 121.607 64.2583Z"
        fill="#001F5C"
      />
      <path
        d="M116.423 60.0437C116.086 59.758 116.044 59.2531 116.33 58.9161L128.619 44.4204C128.904 44.0834 129.409 44.0418 129.746 44.3275C130.083 44.6132 130.125 45.118 129.839 45.455L117.551 59.9508C117.265 60.2878 116.76 60.3294 116.423 60.0437Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.8541 81.6901C99.6749 81.606 99.4644 81.5879 99.2618 81.6554C98.8426 81.795 98.6159 82.248 98.7555 82.6672L109.652 115.389C109.765 115.726 110.086 115.949 110.442 115.935C110.797 115.922 111.101 115.676 111.187 115.331L115.129 99.6205L131.147 97.2167C131.499 97.1639 131.774 96.8854 131.822 96.533C131.869 96.1806 131.679 95.839 131.354 95.6945L99.8541 81.6901ZM113.707 98.7147L110.303 112.279L100.847 83.8823L128.196 96.0417L114.392 98.1132L113.707 98.7147Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.88 69.2695C37.0188 69.2695 33.8887 66.1393 33.8887 62.2781C33.8887 58.4169 37.0188 55.2868 40.88 55.2868C44.7412 55.2868 47.8713 58.4169 47.8713 62.2781C47.8713 66.1393 44.7412 69.2695 40.88 69.2695ZM35.4887 62.2781C35.4887 65.2557 37.9024 67.6695 40.88 67.6695C43.8575 67.6695 46.2713 65.2557 46.2713 62.2781C46.2713 59.3006 43.8575 56.8868 40.88 56.8868C37.9024 56.8868 35.4887 59.3006 35.4887 62.2781Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2 62.2783C27.2 54.6178 33.4101 48.4077 41.0706 48.4077C48.7311 48.4077 54.9411 54.6178 54.9411 62.2783C54.9411 69.9388 48.7311 76.1488 41.0706 76.1488C33.4101 76.1488 27.2 69.9388 27.2 62.2783ZM41.0706 50.0077C34.2937 50.0077 28.8 55.5014 28.8 62.2783C28.8 69.0551 34.2937 74.5488 41.0706 74.5488C47.8474 74.5488 53.3411 69.0551 53.3411 62.2783C53.3411 55.5014 47.8474 50.0077 41.0706 50.0077Z"
        fill="#001F5C"
      />
    </svg>
  );
};
