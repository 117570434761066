export const TOP_ETH_SYMBOL_ADDRESSES: Record<string, string> = {
"1inch": "0x111111111117dc0aa78b770fa6a738034120c302",
 "aave": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
 "ae": "0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d",
 "akita": "0x3301ee63fb29f863f2333bd4466acb46cd8323e6",
 "alice": "0xac51066d7bec65dc4589368da368b212745d63e8",
 "amp": "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
 "ankr": "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
 "ape": "0x4d224452801aced8b2f0aebe155379bb5d594381",
 "audio": "0x18aaa7115705e8be94bffebde57af9bfc265b998",
 "axs": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
 "band": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
 "bat": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
 "bit": "0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5",
 "bix": "0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069",
 "blur": "0x5283d291dbcf85356a21ba090e6db59121208b44",
 "bnb": "0x0000000000000000000000000000000000000000",
 "box": "0x045414e728067ab3da4bceafc0d992d59183463a",
 "btc": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
 "busd": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
 "bzz": "0x19062190b1925b5b6689d7073fdfc8c2976ef8cb",
 "c98": "0xae12c5930881c53715b369cec7606b70d8eb229f",
 "chz": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
 "cnb": "0xec2a0550a2e4da2a027b3fc06f70ba15a94a6dac",
 "cocos": "0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c",
 "cro": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
 "crv": "0xd533a949740bb3306d119cc777fa900ba034cd52",
 "cvc": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
 "cvx": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
 "dai": "0x6b175474e89094c44da98b954eedeac495271d0f",
 "dodo": "0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd",
 "dydx": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
 "eczz": "0x150bbcff6b1b4d528b48f1a300585dea0b6490b6",
 "eden": "0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559",
 "elf": "0xbf2179859fc6d5bee9bf9158632dc51678a4100e",
 "elon": "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
 "enj": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
 "ens": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
 "etc": "0x0000000000000000000000000000000000000000",
 "eth": "0x0000000000000000000000000000000000000000",
 "eurt": "0xc581b735a1688071a1746c968e0798d642ede491",
 "flow": "0xc943c5320b9c18c153d1e2d12cc3074bebfb31a2",
 "ftm": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
 "ftx token": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
 "gal": "0x5faa989af96af85384b8a938c2ede4a7378d9875",
 "gala": "0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
 "gnt": "0xa74476443119a942de498590fe1f2454d7d4ac0d",
 "grt": "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
 "gt": "0xe66747a101bff2dba3697199dcce5b743b454759",
 "gtc": "0xde30da39c46104798bb5aa3fe8b9e0e1f348163f",
 "hmt": "0xd1ba9bac957322d6e8c07a160a3a8da11a0d2867",
 "ht": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
 "inj": "0xe28b3b32b6c345a34ff64674606124dd5aceca30",
 "iost": "0xfa1a856cfa3409cfa145fa4e20eb270df3eb21ab",
 "iotx": "0x6fb3e0a217407efff7ca062d46c26e5d60a14d69",
 "jasmy": "0x7420b4b9a0110cdc71fb720908340c03f9bc03ec",
 "jpyc": "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
 "kishu": "0xa2b4c0af19cc16a6cfacce81f192b024d625817d",
 "knc": "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
 "ldo": "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
 "leo": "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
 "link": "0x514910771af9ca656af840dff83e8264ecf986ca",
 "loom": "0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0",
 "lpt": "0x58b6a8a3302369daec383334672404ee733ab239",
 "lrc": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
 "maed": "0xdd6b2e978ac26cab895d1bb21e60aa7b526350e0",
 "mana": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
 "mask": "0x69af81e73a73b40adf4f3d4223cd9b1ece623074",
 "matic": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
 "mkr": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
 "mx": "0x11eef04c884e24d9b7b4760e7476d06ddf797f36",
 "nas": "0x5d65d971895edc438f465c17db6992698a52318d",
 "ncash": "0x809826cceab68c387726af962713b64cb5cb3cca",
 "nu": "0x4fe83213d56308330ec302a8bd641f1d0113a4cc",
 "nuls": "0xb91318f35bdb262e9423bc7c7c2a3a93dd93c92c",
 "ocn": "0x4092678e4e78230f46a1534c0fbc8fa39780892b",
 "ogn": "0x8207c1ffc5b6804f6024322ccf34f29c3541ae26",
 "okb": "0x75231f58b43240c9718dd58b4967c5114342a86c",
 "omg": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
 "one": "0x946551dd05c5abd7cc808927480225ce36d8c475",
 "oxt": "0x4575f41308ec1483f3d399aa9a2826d74da13deb",
 "pax": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
 "paxg": "0x45804880de22913dafe09f4980848ece6ecbaf78",
 "pay": "0xb97048628db6b661d4c2aa833e95dbe1a905b280",
 "people": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
 "rare": "0xba5bde662c17e2adff1075610382b9b691296350",
 "rep": "0xe94327d07fc17907b4db788e5adf2ed424addff6",
 "rlc": "0x607f4c5bb672230e8672085532f7e901544a7375",
 "rly": "0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b",
 "rndr": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
 "ruff": "0xf278c1ca969095ffddded020290cf8b5c424ace2",
 "rum": "0x72313959c0346016bfba17fa29dcea109f3aa348",
 "saito": "0xfa14fa6958401314851a17d6c5360ca29f74b57b",
 "sand": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
 "sdoge-uqn": "0xc90e7c93a6871a7c5f319a8515f48fff0916cc61",
 "shib": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
 "slp": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
 "snt": "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
 "srm": "0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
 "srn": "0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25",
 "storj": "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
 "sushi": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
 "tel": "0x467bccd9d29f223bce8043b84e8c8b282827790f",
 "tnb": "0xf7920b0768ecb20a123fac32311d07d193381d6f",
 "tusd": "0x0000000000085d4780b73119b644ae5ecd22b376",
 "tyc": "0x3a82d3111ab5faf39d847d46023d9090261a658f",
 "uip": "0x4290563c2d7c255b5eec87f2d3bd10389f991d68",
 "uni": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
 "usdc": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
 "usdt": "0xdac17f958d2ee523a2206206994597c13d831ec7",
 "uuu": "0x3543638ed4a9006e4840b105944271bcea15605d",
 "ven": "0xd850942ef8811f2a866692a623011bde52a462c1",
 "wbtc": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
 "weth": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
 "wom": "0xbd356a39bff2cada8e9248532dd879147221cf76",
 "woo": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
 "xaut": "0x68749665ff8d2d112fa859aa293f07a622782f38",
 "xin": "0xa974c709cfb4566686553a20790685a47aceaa33",
 "yfi": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
 "zrx": "0xe41d2489571d322189246dafa5ebde1f4699f498"
};
