import React from 'react';
import {IconType} from '../../icons';

export const Settings: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M100.855 99.3477H110.983C112.275 99.3477 113.322 100.395 113.322 101.686C113.322 102.977 112.275 104.024 110.983 104.024H100.439C99.2801 106.741 96.5851 108.645 93.4454 108.645C90.3057 108.645 87.6107 106.741 86.4519 104.024H49.0166C47.7251 104.024 46.6782 102.977 46.6782 101.686C46.6782 100.395 47.7251 99.3477 49.0166 99.3477H86.036C86.8071 95.9676 89.8317 93.4453 93.4454 93.4453C97.0591 93.4453 100.084 95.9676 100.855 99.3477Z"
        fill="#3164FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.926 60.2996C101.004 59.8631 101.045 59.4136 101.045 58.9547C101.045 54.7575 97.6426 51.355 93.4454 51.355C89.2482 51.355 85.8457 54.7575 85.8457 58.9547C85.8457 59.4136 85.8864 59.8631 85.9643 60.2996H48.828C48.0825 60.2996 47.4782 59.6953 47.4782 58.9498C47.4782 58.2043 48.0825 57.6 48.828 57.6H85.9657C86.0655 57.0433 86.226 56.5076 86.44 56H48.828C47.1989 56 45.8782 57.3207 45.8782 58.9498C45.8782 60.5789 47.1989 61.8996 48.828 61.8996H86.4373C87.5879 64.6343 90.2924 66.5544 93.4454 66.5544C96.5984 66.5544 99.3029 64.6343 100.453 61.8996H111.172C112.801 61.8996 114.122 60.5789 114.122 58.9498C114.122 57.3207 112.801 56 111.172 56H100.452C100.666 56.5076 100.826 57.0433 100.926 57.6H111.172C111.917 57.6 112.522 58.2043 112.522 58.9498C112.522 59.6953 111.917 60.2996 111.172 60.2996H100.926ZM93.4454 64.9544C96.7589 64.9544 99.4451 62.2682 99.4451 58.9547C99.4451 55.6411 96.7589 52.955 93.4454 52.955C90.1319 52.955 87.4457 55.6411 87.4457 58.9547C87.4457 62.2682 90.1319 64.9544 93.4454 64.9544Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5602 82.9499C72.4085 85.682 69.7053 87.5998 66.5542 87.5998C62.357 87.5998 58.9545 84.1973 58.9545 80.0001C58.9545 75.8029 62.357 72.4004 66.5542 72.4004C69.7053 72.4004 72.4084 74.3182 73.5602 77.0503H111.172C112.801 77.0503 114.122 78.371 114.122 80.0001C114.122 81.6292 112.801 82.9499 111.172 82.9499H73.5602ZM74.0343 81.3499C74.1129 80.9118 74.1539 80.4607 74.1539 80.0001C74.1539 79.5394 74.1129 79.0884 74.0343 78.6503H111.172C111.917 78.6503 112.522 79.2546 112.522 80.0001C112.522 80.7456 111.917 81.3499 111.172 81.3499H74.0343ZM66.5542 85.9998C69.8677 85.9998 72.5539 83.3136 72.5539 80.0001C72.5539 76.6865 69.8677 74.0004 66.5542 74.0004C63.2406 74.0004 60.5545 76.6865 60.5545 80.0001C60.5545 83.3136 63.2406 85.9998 66.5542 85.9998Z"
        fill="#001F5C"
      />
      <path
        d="M59.5481 77.0503H48.828C47.1988 77.0503 45.8782 78.371 45.8782 80.0001C45.8782 81.6292 47.1988 82.9499 48.828 82.9499H59.5479C59.334 82.4423 59.1735 81.9066 59.0738 81.3499H48.828C48.0825 81.3499 47.4782 80.7456 47.4782 80.0001C47.4782 79.2546 48.0825 78.6503 48.828 78.6503H59.0738C59.1736 78.0936 59.3341 77.5579 59.5481 77.0503Z"
        fill="#001F5C"
      />
    </svg>
  );
};
