import React from 'react';
import {IconType} from '../../icons';

export const MagnifyingGlass: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M86.9235 36.2158C66.9999 36.2158 50.8471 52.3687 50.8471 72.2923C50.8471 80.3891 53.5481 87.8397 58.053 93.8597L41.4358 110.477C38.986 112.927 38.986 116.899 41.4358 119.349V119.349C43.8857 121.798 47.8577 121.798 50.3075 119.349L67.185 102.471C72.86 106.188 79.633 108.369 86.9235 108.369C106.847 108.369 123 92.2159 123 72.2923C123 52.3687 106.847 36.2158 86.9235 36.2158ZM86.9235 98.9575C72.22 98.9575 60.2583 86.9958 60.2583 72.2923C60.2583 57.5888 72.22 45.6271 86.9235 45.6271C101.627 45.6271 113.589 57.5888 113.589 72.2923C113.589 86.9958 101.627 98.9575 86.9235 98.9575Z"
        stroke="#001F5C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.6295 53.4697H84.2183C83.1137 53.4697 82.2183 54.3652 82.2183 55.4697V85.9776C82.2183 87.0822 83.1137 87.9776 84.2183 87.9776H89.6295C90.7341 87.9776 91.6295 87.0822 91.6295 85.9776V55.4697C91.6295 54.3652 90.7341 53.4697 89.6295 53.4697Z"
        fill="#3164FA"
      />
      <path
        d="M77.0812 66.0181H71.6699C70.5654 66.0181 69.6699 66.9135 69.6699 68.0181V85.9776C69.6699 87.0822 70.5654 87.9776 71.6699 87.9776H77.0812C78.1857 87.9776 79.0812 87.0822 79.0812 85.9776V68.0181C79.0812 66.9135 78.1857 66.0181 77.0812 66.0181Z"
        fill="#3164FA"
      />
      <path
        d="M96.7664 59.7441H102.178C103.282 59.7441 104.178 60.6396 104.178 61.7441V85.9779C104.178 87.0825 103.282 87.9779 102.178 87.9779H96.7664C95.6618 87.9779 94.7664 87.0825 94.7664 85.9779V61.7441C94.7664 60.6396 95.6618 59.7441 96.7664 59.7441Z"
        fill="#3164FA"
      />
    </svg>
  );
};
